<template>
  <div v-if="activeLoading.licensePlateNumber">
    <h1>LKW <span class="key heavy">{{ activeLoading.licensePlateNumber }}</span></h1>
    <div v-if="activeLoading.vessel.length">
      <h2>Ladeliste</h2>
      <ul class="list w-100">
        <li v-for="vessel in activeLoading.vessel"
            :key="vessel.vesselId" class="row">
          <label class="col-3">{{ vessel.uniqueNumber }}</label>
          <div class="col-2 text-right">{{ formattedInt(vessel.weight) }} kg</div>
          <div v-if="finishedLoading()" class="col-3 text-right">
            <api-pdf-link method="bigbagslip" :elementId="vessel.vesselId" class="float-right"/>
          </div>
        </li>
      </ul>
      <div class="row mb-3">
        <div class="col-3 bold">Summe</div>
        <div class="col-2 bold text-right">{{ totalWeight() }} kg</div>
        <div v-if="finishedLoading()" class="col-3 text-right">
          <api-pdf-link method="loadingslip" :elementId="activeLoading.loadingId" class="float-right"/>
        </div>
      </div>
    </div>
    <div v-if="!finishedLoading()">
      <h2>Big Bags</h2>
      <ul class="nav" style="margin:0 0 0.5rem 0">
        <li @click="showQrReader" class="qr">
          QR-Code
          <img src="@/assets/images/icon-qr-code.svg"/>
        </li>
      </ul>
      <form @submit.prevent="saveBagLoading" class="col-8">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="bag">Big Bag</label>
          </div>
          <select
              v-model="bag"
              class="custom-select custom-select-lg" id="bag">
            <option>Auswählen..</option>
            <option
                v-for="bag in vessels()"
                :key="bag.vesselId"
                :value="bag.vesselId">
              {{ bag.uniqueNumber }} | {{ bag.weight }} kg
            </option>
          </select>
        </div>
        <button type="submit" class="mt-1">Geladen</button>
      </form>
      <button @click="finishLoading">Abschluss</button>
    </div>
  </div>
  <div v-else-if="!finishedLoading()" class="row">
    <h1>Verladen</h1>
    <form @submit.prevent="save" class="col-8">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="truck">Kennzeichen</label>
        </div>
        <input v-model="truck" type="text" class="form-control form-control-lg" id="truck" placeholder="">
      </div>
      <button type="submit">Starten</button>
    </form>
  </div>
  <app-back-button routerLink="LoadingPage"/>
  <app-qr-reader v-if="qr" :doEmit="true" @qrRead="qrRead"/>
</template>

<script>
import ApiPdfLink from "@/components/ApiPdfLink";

export default {
  name: "LoadingShow",
  components: {ApiPdfLink},
  data() {
    return {
      loadingId: null,
      activeLoading: {
        vessel: []
      },
      truck: '',
      bag: '',
      loadingDone: false,
      storedBags: [],
      qr: false
    }
  },
  props: {
    id: {
      required: false,
      type: String
    }
  },
  async created() {
    if (this.id) {
      await this.getActiveLoading()
      this.loadingId = this.id
    }
    this.$store.state.vessel = []
    await this.getLoadableVessels()
  },
  methods: {
    vessels() {
      return this.$store.state.vessel
    },
    totalWeight() {
      const vessels = this.activeLoading.vessel
      let total = 0

      for (let idx in vessels) {
        total += parseInt(vessels[idx]['weight'])
      }
      return this.formattedInt(total)
    },
    async save() {
      const data = {
        licensePlateNumber: this.truck
      }
      const response = await this.$store.dispatch('cruItem', {name: 'loading', data})
      this.loadingId = response.id
      this.activeLoading.licensePlateNumber = this.truck
    },
    async saveBagLoading() {
      const data = {
        loadingId: this.id
      }
      await this.$store.dispatch('cruItem', {name: 'vessel', id: this.bag, data})
      await this.getActiveLoading()

      // const bagIndex = this.storedBags.findIndex(bag => bag.id === this.bag)
      // this.storedBags.splice(bagIndex, 1)
      await this.getLoadableVessels()
    },
    async finishLoading() {
      const data = {
        finishedLoading: 1
      }
      const response = await this.$store.dispatch('cruItem',
          {name: 'loading', id: this.id, data})

      if (response && response.success) {
        this.activeLoading.finishedLoading = true
      }
      // this.$props.batch.bagged = true
    },
    finishedLoading() {
      console.log('###',this.activeLoading.finishedLoading)
      if (this.activeLoading.finishedLoading == '0')
        return false

      return this.activeLoading.finishedLoading
    },
    async getActiveLoading() {
      this.activeLoading = await this.$store.dispatch('getItem', {name: 'loading', id: this.id})
    },
    async getLoadableVessels() {
      await this.$store.dispatch('getItemList', {entityName: 'vessel', method: 'dried'})
    },
    showQrReader() {
      this.qr = true
    },
    qrRead(params) {
      this.setBagId(params.qrString)
      this.saveBagLoading()
      this.qr = false
    },
    formattedInt(number) {
      return parseInt(number).toLocaleString('de-DE')
    },
    setBagId(id) {
      const pos = id.indexOf('.')
      if (pos >= 0)
        id = id.substring(pos+1)
      if (id.indexOf('BB') === 0)
        id = id.substring(2)

      this.bag = id
    }
  }
}
</script>

<style scoped>

</style>