<template>
  <h1>Lager</h1>
  <div
      v-for="regrater in regraters()"
      :key="regrater.contactId">
    <div
        v-if="regraterTotal(regrater.contactId) > 0">
      <h2>{{ regrater.name }}</h2>
      <ul class="subnav mb-0">
        <router-link
            v-for="vessel in getVesselsByRegrater(regrater.contactId)"
            :key="vessel.vesselId"
            :to="{name: 'BatchShow', params: {id: vessel.batchId}}">
          <li class="row">
            <label class="col-3">{{ vessel.uniqueNumber }}</label>
            <div class="col-2 text-right">{{ formattedInt(vessel.weight) }} kg</div>
            <div class="col-3"></div>
          </li>
        </router-link>
      </ul>
      <div class="row mb-3">
        <div class="col-3 bold">Summe</div>
        <div class="col-2 bold text-right">{{ regraterTotal(regrater.contactId) }} kg</div>
      </div>
    </div>
  </div>
  <app-back-button/>
</template>

<script>
export default {
  name: "StockPage",
  async created() {
    this.$store.state.vessel = []
    await this.$store.dispatch('getItemList', {entityName: 'vessel', method: 'dried'})
    await this.$store.dispatch('initItems', {entityName: 'regrater'})
  },
  methods: {
    regraters() {
      return this.$store.state.regrater
      // return this.$store.state.batch.filter(batch => batch.washedWeight === null)
    },
    getVesselsByRegrater(contactId) {
      return this.$store.state.vessel.filter(vessel => vessel.regraterId == contactId)
    },
    regraterTotal(contactId) {
      const vessels = this.getVesselsByRegrater(contactId)
      let total = 0

      for (let idx in vessels) {
        total += parseInt(vessels[idx]['weight'])
      }
      return this.formattedInt(total)
    },
    formattedInt(number) {
      return parseInt(number).toLocaleString('de-DE')
    }
  }
}
</script>

<style scoped>
h2 {
  padding-top: 0.5rem;
}

.subnav li:after {
  margin-left: -16px;
}
</style>