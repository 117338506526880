<template>
  <form :action="formLink" target="_blank" method="post">
    <input v-model="userToken" type="hidden" name="userToken"/>
    <input v-model="userId" type="hidden" name="userId"/>
    <button class="blank">{{ label }}</button>
  </form>
</template>

<script>
export default {
  name: "ApiPdfLink",
  data() {
    return {
      formLink: process.env.VUE_APP_API_BASE + process.env.VUE_APP_INSTANCE
          + '/pdf/' + this.$props.method + '/' + this.$props.elementId
    }
  },
  props: {
    method: {
      type: String,
      required: true
    },
    elementId: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      default: 'PDF',
      required: false
    }
  },
  computed: {
    userToken() {
      return this.$store.getters.userToken
    },
    userId() {
      return this.$store.getters.userId
    },
    entityId() {
      return this.$props.elementId
    }
  }
}
</script>

<style scoped>

</style>