import {createStore} from 'vuex'
import data from '@/data'

import getters from '@/store/getters'
import actions from '@/store/actions'
import mutations from '@/store/mutations'

export default createStore({
    state: {
        ...data,
        title: '',
        authId: null,
        userId: null,
        userPermission: null,
        userToken: null,
        tokenExpiration: null,
        contact: [],
        supplier: [],
        batch: [],
        regrater: []
    },
    getters,
    actions,
    mutations
})