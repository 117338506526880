<template>
  <h2>Charge</h2>
  <form @submit.prevent="save">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="supplierId">Lieferant</label>
      </div>
      <select
          v-model="supplierId"
          @change="supplierChanged"
          class="custom-select custom-select-lg" id="supplierId">
        <option>Auswählen..</option>
        <option
            v-for="supplier in suppliers"
            :key="supplier.contactId"
            :value="supplier.contactId">{{ supplier.name }}, {{ supplier.city }}</option>
      </select>
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="qualityStandard">Standard</label>
      </div>
      <select
          v-model="qualityStandard"
          class="custom-select custom-select-lg" id="qualityStandard">
        <option>Auswählen..</option>
        <option
            v-for="qualityStandard in qualityStandards"
            :key="qualityStandard.id"
            :value="qualityStandard.id">{{ qualityStandard.name }}</option>
      </select>
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="regraterId">Aufkäufer</label>
      </div>
      <select
          v-model="regraterId"
          class="custom-select custom-select-lg" id="regraterId">
        <option>Auswählen..</option>
        <option
            v-for="regrater in regraters"
            :key="regrater.contactId"
            :value="regrater.contactId">{{ regrater.name }}</option>
      </select>
    </div>
<!--    <div class="input-group mb-3">-->
<!--      <div class="input-group-prepend">-->
<!--        <label class="input-group-text" for="exampleFormControlInput2">Nassgewicht</label>-->
<!--      </div>-->
<!--      <input v-model="newBatchAmount" type="number" class="form-control form-control-lg" id="exampleFormControlInput2" placeholder="">-->
<!--      <div class="input-group-append">-->
<!--        <span class="input-group-text">kg</span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <input type="file" accept="image/*" capture="camera" /><br><br>-->
    <button type="submit">Hinzufügen</button>
  </form>
</template>

<script>
export default {
  name: "BatchEditor",
  data() {
    return {
      supplierId: '',
      regraterId: '',
      qualityStandard: 0
      // newBatchAmount: '',
    }
  },
  created() {
    this.$store.dispatch('getItemList', {entityName:'supplier'})
    this.$store.dispatch('getItemList', {entityName:'regrater'})
  },
  computed: {
    suppliers() {
      return this.$store.state.supplier
    },
    regraters() {
      return this.$store.state.regrater
    },
    qualityStandards() {
      return this.$store.state.qualityStandards
    }
  },
  methods: {
    supplierChanged() {
      this.qualityStandard = this.$store.state.supplier.find(
          item => item.contactId === this.supplierId).supplier_qualityStandard
    },
    async save() {
      const batch = {
        supplierId: this.supplierId,
        regraterId: this.regraterId,
        qualityStandard: this.qualityStandard,
        bags: []
        // wetWeight: this.newBatchAmount,
      }

      const response = await this.$store.dispatch('cruItem', {name: 'batch', data: batch})
      if (response && response.success === true) {
        this.openNewBatch(response.id)
      }
      // this.$emit('save', {batch})
      // this.batches.push(batch)
      // this.newBatchAmount = ''

      // this.$store.dispatch('createBatch', batch).then(this.openNewBatch.bind(this))
    },
    openNewBatch(id) {
      this.$router.push({name: 'BatchShow', params: { id }})

      /*
      // clear and close form
      this.supplierId = ''
      this.regraterId = ''
      this.newBatchAmount = ''
      this.edit = false
       */
    }
  }
}
</script>

<style scoped>

</style>