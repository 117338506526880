<template>
  <div
      v-if="workflowState === 0"
      class="w-100">
    <h2>Nassbehälter</h2>
    <div
        v-if="getWetVessels().length">
      <ul class="output">
        <li
            v-for="bag in getWetVessels()"
            :key="bag.vesselId">
          <label class="col-2">{{ bag.uniqueNumber }}</label> {{ parseInt(bag.weight) }} kg
        </li>
      </ul>
    </div>
    <form @submit.prevent="saveBag"
          class="row">
      <div class="input-group col-6">
        <div class="input-group-prepend">
          <label class="input-group-text" for="wetWeight">Nass</label>
        </div>
        <input v-model="wetWeight" type="number" class="form-control form-control-lg"
               id="wetWeight" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">kg</span>
        </div>
      </div>
      <button class="col-2 mt-0 mb-0 pt-0 pb-0" type="submit" style="text-align: center">+</button>
    </form>
    <div
        v-if="getWetVessels().length">
      <h2>Nassgewicht</h2>
      <ul class="output">
        <li>
          <label class="col-2">Gesamt</label> {{ totalWeight(1) }} kg
        </li>
      </ul>
      <h2>Notizen</h2>
      <form class="row">
        <div class="form-group col-8">
          <label for="exampleFormControlTextarea1">Qualität</label>
          <textarea v-model="noteQuality" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
<!--        <div class="custom-file">-->
<!--          <input type="file" class="custom-file-input" accept="image/*" capture="camera" id="customFile"/>-->
<!--          <label class="custom-file-label" for="customFile">Foto</label>-->
<!--        </div>-->
        <!--        <input type="file" accept="image/*" capture="camera" id="customFile2"/>-->
      </form>
      <button @click="finishWashing" type="button" class="done">Abschluss</button>
    </div>
  </div>
  <div
      v-else-if="workflowState === 1"
      class="w-100">
    <h2>Big Bags</h2>
    <div
        v-if="getDryVessels().length">
      <ul class="output">
        <li
            v-for="bag in getDryVessels()"
            :key="bag.vesselId">
          <label class="col-2">{{ bag.uniqueNumber }}</label> {{ parseInt(bag.weight) }} kg
          <api-pdf-link method="bigbagslip" :elementId="bag.vesselId" class="float-right"/>
        </li>
      </ul>
    </div>
    <form @submit.prevent="saveBag"
          class="row">
      <div class="input-group col-6">
        <div class="input-group-prepend">
          <label class="input-group-text" for="dryWeight">Trocken</label>
        </div>
        <input v-model="dryWeight" type="number" class="form-control form-control-lg"
               id="dryWeight" placeholder="">
        <div class="input-group-append">
          <span class="input-group-text">kg</span>
        </div>
      </div>
      <button class="col-2 mt-0 mb-0 pt-0 pb-0" type="submit" style="text-align: center">+</button>
    </form>
    <div
        v-if="getDryVessels().length">
      <h2>Trockengewicht</h2>
      <ul class="output">
        <li>
          <label class="col-2">Gesamt</label> {{ totalWeight(2) }} kg
        </li>
      </ul>
      <button @click="finishDrying" type="button" class="done">Abschluss</button>
    </div>
  </div>
  <div
      v-else-if="workflowState === 2 && getDryVessels().length" class="col-8" >
    <h2>Big Bags</h2>
    <div
        v-if="getDryVessels().length">
      <ul class="output">
        <li
            v-for="bag in getDryVessels()"
            :key="bag.vesselId">
          <label class="col-2">{{ bag.uniqueNumber }}</label> {{ parseInt(bag.weight) }} kg
          <api-pdf-link method="bigbagslip" :elementId="bag.vesselId" class="float-right"/>
        </li>
      </ul>
    </div>
    <form @submit.prevent="saveBag"
          class="row">
    </form>
  </div>
  <!--  <div-->
  <!--      v-else-if="workflowState === 1"-->
  <!--      class="row">-->
  <!--    <h2>Big Bags</h2>-->
  <!--    <form @submit.prevent="save" class="col-8">-->
  <!--      &lt;!&ndash;      <input name="formName" value="dry" type="hidden"/>&ndash;&gt;-->
  <!--      <div class="input-group mb-3">-->
  <!--        <div class="input-group-prepend">-->
  <!--          <label class="input-group-text" for="weight">Gewicht</label>-->
  <!--        </div>-->
  <!--        <input v-model="weight" type="number" class="form-control form-control-lg"-->
  <!--               id="weight" placeholder="">-->
  <!--        <div class="input-group-append">-->
  <!--          <span class="input-group-text">kg</span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <button type="submit">Hinzufügen</button>-->
  <!--    </form>-->
  <!--  </div>-->
</template>

<script>
import ApiPdfLink from "@/components/ApiPdfLink";
export default {
  name: "BagList",
  components: {ApiPdfLink},
  data() {
    return {
      activeBags: [...this.bags],
      weight: null,
      wetWeight: null,
      dryWeight: null,
      noteQuality: ''
    }
  },
  props: {
    batch: {
      type: Object,
      required: true
    },
    bags: {
      type: Array,
      required: true
    },
    workflowState: {
      type: Number,
      required: false
    }
  },
  methods: {
    getWetVessels() {
      // console.log('getwetvessels',this.$props.bags)
      let bags = [];
      for (let idx in this.$props.bags) {
        if (this.$props.bags[idx].workflowState == 1) {
          bags.push(this.$props.bags[idx])
        }
      }
      return bags
    },
    getDryVessels() {
      let bags = [];
      for (let idx in this.$props.bags) {
        if (this.$props.bags[idx].workflowState == 2) {
          bags.push(this.$props.bags[idx])
        }
      }
      return bags
    },
    async saveBag() {
      let bag = {}

      if (this.$props.workflowState == 0) {
        bag = {
          weight: this.wetWeight,
          workflowState: 1
        }
        this.wetWeight = ''
      } else { // workflowState === 1
        bag = {
          weight: this.dryWeight,
          workflowState: 2
        }
        this.dryWeight = ''
      }
      bag['batchId'] = this.batch.batchId

      const response = await this.$store.dispatch('cruItem',
          {name: 'vessel', data: bag, parentEntity: 'batch', parentId: this.batch.batchId})
      if (response && response.success === true) {
        if (this.$props.workflowState === 0)
          this.getWashed()
        else
          this.getDried()
      }

      // this.$store.dispatch('createBag', {batchId: this.$props.batch.id, bag}).then(this.checkIfBagged.bind(this))
    },
    getWashed() {
      this.$store.dispatch('getItemsByParent', {
        entityName: 'vessel', parentEntity: 'batch',
        parentId: this.batch.batchId, method: 'washed'
      })
    },
    getDried() {
      this.$store.dispatch('getItemsByParent', {
        entityName: 'vessel', parentEntity: 'batch',
        parentId: this.batch.batchId, method: 'dried'
      })
    },

    totalWeight(workflowState) {
      let total = 0;
      for (let idx in this.$props.bags) {
        if (this.$props.bags[idx].workflowState == workflowState) {
          total += parseInt(this.$props.bags[idx].weight)
        }
      }
      return total
    },
    async finishWashing() {
      const data = {
        washedWeight: this.totalWeight(1),
        washedQuality: this.noteQuality
      }
      const response = await this.$store.dispatch('cruItem',
          {name: 'batch', id: this.batch.batchId, data})

      if (response && response.success) {
        this.$props.batch.washedQuality = this.noteQuality
        this.$props.batch.washedWeight = this.totalWeight(1)
      }
      // this.$props.workflowState = 1
    },
    async finishDrying() {
      const data = {
        driedWeight: this.totalWeight(2)
      }
      const response = await this.$store.dispatch('cruItem',
          {name: 'batch', id: this.batch.batchId, data})

      if (response && response.success) {
        this.$props.batch.driedWeight = this.totalWeight(2)
      }
      // this.$props.batch.bagged = true
    },
    checkIfBagged() {
      // let bagsWeight = 0
      //
      // for(let i in this.$props.batch.bags) {
      //   bagsWeight += this.$props.batch.bags[i].weight
      // }
      //
      // if (bagsWeight === this.$props.batch.dryWeight) {
      //   this.$props.batch.bagged = true
      // }
    }
  }
}
</script>

<style scoped>

</style>