<template>
  <h1>Verladen</h1>
  <ul class="subnav">
    <router-link
        v-for="loading in loadings()"
        :key="loading.loadingId"
        :to="{name: 'LoadingShow', params: {id: loading.loadingId}}">
      <li>
        <label>{{ loading.licensePlateNumber }}</label>
      </li>
    </router-link>
  </ul>
  <div class="row">
    <div class="col-8">
      <router-link :to="{name:'LoadingShow', params: {id: 0}}">
        <button>Starten</button>
      </router-link>
    </div>
  </div>
  <div v-if="latest">
    <h2>Letzte</h2>
    <ul class="subnav">
      <router-link
          v-for="loading in latest"
          :key="loading.loadingId"
          :to="{name: 'LoadingShow', params: {id: loading.loadingId}}">
        <li>
          <label>{{ loading.licensePlateNumber }}</label>
        </li>
      </router-link>
    </ul>
  </div>
  <app-back-button/>
</template>

<script>
export default {
  name: "LoadingPage",
  data() {
    return {
      latest: null
    }
  },
  async created() {
    await this.$store.dispatch('getItemList', {entityName: 'loading'})
    this.latest = await this.$store.dispatch('getItemList', {entityName: 'loading', method: 'latest', returnData: true})
  },
  methods: {
    loadings() {
      return this.$store.state.loading
    }
  }
}
</script>

<style scoped>

</style>