import axios from 'axios';

export default {
    async signIn({commit}, {email, password}) {
        let result = {};

        await axios.post(process.env.VUE_APP_API_BASE + process.env.VUE_APP_INSTANCE
            + '/auth/login', {
            email,
            password
        })
            .then(response => {
                if (response.data.success) {
                    const loginData = {
                        userId: response.data.userId,
                        userToken: response.data.loginToken,
                        userPermission: response.data.permission,
                        tokenExpiration: response.data.timeoutDateTime
                    }
                    commit('setUserStorage', loginData)
                    commit('setUser', loginData)
                    result = {success: true}
                } else {
                    result = {err: response.data.err}
                }
            })
            .catch(e => {
                // console.log(e)
                result = {err: e.code + ': ' + e.message}
            });

        return result
    },
    async signOut({commit,state}) {
        await axios.get(process.env.VUE_APP_API_BASE + process.env.VUE_APP_INSTANCE
            + '/auth/logout', {
            headers: {
                Authorization: state.userToken +'.'+ state.userId
            }
        })
        commit('unsetUser')
    },
    async tryLogin({commit}) {
        const userId = localStorage.getItem('userId')
        const userToken = localStorage.getItem('userToken')

        // check session on server
        const response = await axios.get(process.env.VUE_APP_API_BASE + process.env.VUE_APP_INSTANCE
            + '/auth/alive', {
            headers: {
                Authorization: userToken +'.'+ userId
                // Authorization: JSON.stringify({
                //     userId,
                //     userToken
                // })
            }
        })
        if (response.data.isAlive) {
            commit('setUser', {
                userId,
                userToken,
                userPermission: localStorage.getItem('userPermission'),
                tokenExpiration: localStorage.getItem('tokenExpiration')
            })
            return true
        } else {
            return false
        }
    },
    async initItems({state, dispatch}, {entityName, forceReload}) {
        if (!state[entityName].length || forceReload === true) {
            await dispatch('getItemList', {entityName})
        }
    },
    async getItemList({state, commit}, {entityName, method, returnData}) {
        // console.log('getItemList', entityName)
        method = method ? method : 'list'

        const response = await axios.get(process.env.VUE_APP_API_BASE + process.env.VUE_APP_INSTANCE
            + '/' + entityName + '/' + method, {
            headers: {
                Authorization: state.userToken +'.'+ state.userId
            }
        })
        // console.log('gotList', entityName, response.data)
        if (response.data) {
            if (returnData) return response.data
            else await commit('setItemList', {entityName, list: response.data})
        }
        return true
    },
    async getItemsByParent({state, commit}, {entityName, parentEntity, parentId, method}) {
        method = method ? method : 'byparent'

        let url = process.env.VUE_APP_API_BASE + process.env.VUE_APP_INSTANCE
            + '/' + entityName + '/' + method

        if (parentId) url += '?parentId=' + parentId

        const response = await axios.get(url, {
            headers: {
                Authorization: state.userToken +'.'+ state.userId
            }
        })
        // console.log('gotChilds', entityName, response.data)
        if (response.data) {
            commit('setItemChild', {entityName, parentEntity, parentId, list: response.data})
        }
    },
    async getItem({state}, {name, id}) {
        const response = await axios.get(process.env.VUE_APP_API_BASE + process.env.VUE_APP_INSTANCE
            + '/' + name + '/item/' + id, {
            headers: {
                Authorization: state.userToken +'.'+ state.userId
            }
        })
        if (response.data) {
            return response.data
        }
    },
    async cruItem({state, commit}, {name, id, data, parentEntity, parentId}) {
        // console.log('cruItem', name, id, data)

        const response = await axios.post(process.env.VUE_APP_API_BASE + process.env.VUE_APP_INSTANCE
            + '/' + name + '/item/' + id, data, {
            headers: {
                Authorization: state.userToken +'.'+ state.userId
            }
        })
        if (response.data && response.data.success) {
            if (response.data.id) {
                data[name + 'Id'] = response.data.id
            }
            commit('cruItem', {name, id, data, parentEntity, parentId})
            return response.data
        }
    },
    setTitle({commit}, title) {
        commit('setTitle', title)
    },


    createBatch({commit, state}, batch) {
        // console.log(batch)
        const id = 'CH' + state.batch.length + 1;
        const userId = 'U1'
        const publishedAt = Math.floor(Date.now() / 1000)
        const supplier = state.suppliers.find(supplier => supplier.id === batch.supplierId).name
        const regrater = state.regraters.find(regrater => regrater.id === batch.regraterId).name

        batch = {...batch, id, supplier, regrater, userId, publishedAt}
        commit('setBatch', {batch})

        return id
    },
    updateBatch({commit, state}, {id, batch}) {
        const currentBatch = state.batch.find(batch => batch.id === id)

        if (batch.dryWeight && !currentBatch.vessel) {
            batch.vessel = []
        }

        batch = {
            ...currentBatch,
            ...batch
        }

        commit('updateBatch', {id, batch})
    },
    getBatch({state}, {id}) {
        return state.batch.find(batch => batch.id === id)
    },
    createBag({commit, state}, {batchId, bag}) {
        // console.log(batchId)
        const batch = state.batch.find(batch => batch.id === batchId)
        // console.log(batch)
        const bagsCount = batch.vessel ? batch.vessel.length : 0;

        bag = {
            ...bag,
            id: batchId + 'B' + (bagsCount + 1),
            publishedAt: Math.floor(Date.now() / 1000)
        }
        commit('setBag', {bag, batchId})
        // commit('appendBagToBatch', {bagId: bag.id, batchId: bag.batchId})
    },
    getBags({state}, {batchId}) {
        if (batchId) {
            return state.batch.find(batch => batch.id === batchId).batch
        } else {
            let bags = []
            for (let idx in state.batch) {
                if (state.batch[idx].vessel) {
                    bags = [
                        ...bags,
                        ...state.batch[idx].vessel
                    ]
                }
            }
            return bags
        }
    },
    async readQR(state, {file}) {
        if (!file) return null

        let formData = new FormData();
        formData.append('file', file);

        // axios.post('https://api-demo.pit-story.com/actions/read-qr',
        axios.post('/php/read-qr.php',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function () {
                console.log('SUCCESS!!');
            })
            .catch(function () {
                console.log('FAILURE!!');
            });
    }
}