import Home from "@/pages/Home";
import ArrivalShow from "@/pages/ArrivalShow";
import LoadingShow from "@/pages/LoadingShow";
import BatchShow from "@/pages/BatchShow";
import NotFound from "@/pages/NotFound";
import {createRouter, createWebHistory} from 'vue-router'
import IncomingPage from "@/pages/IncomingPage";
import DryingPage from "@/pages/DryingPage";
import StockPage from "@/pages/StockPage";
import LoadingPage from "@/pages/LoadingPage";
import SignIn from "@/pages/SignIn";
import ContactPage from "@/pages/ContactPage";
import store from '@/store'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {title:'', permission: 'read', toTop: true, smoothScroll: true}
    },
    {
        path: '/contact',
        name: 'ContactPage',
        component: ContactPage,
        meta: {title:'', permission: 'admin', toTop: true, smoothScroll: true}
    },
    {
        path: '/incoming',
        name: 'IncomingPage',
        component: IncomingPage,
        meta: {title:'Chargen', permission: 'read', toTop: true, smoothScroll: true}
    },
    {
        path: '/drying',
        name: 'DryingPage',
        component: DryingPage,
        meta: {title:'Chargen', permission: 'read', toTop: true, smoothScroll: true}
    },
    {
        path: '/stock',
        name: 'StockPage',
        component: StockPage,
        meta: {title:'BigBags', permission: 'read', toTop: true, smoothScroll: true}
    },
    {
        path: '/loading',
        name: 'LoadingPage',
        component: LoadingPage,
        meta: {title:'LKWs', toTop: true, smoothScroll: true, permission: 'read'}
    },
    {
        path: '/batch/:id',
        name: 'BatchShow',
        component: BatchShow,
        props: true,
        meta: {title:'', toTop: true, smoothScroll: true, permission: 'read'}
        // beforeEnter(to, from, next) {
        //     const batch = store.dispatch('getBatch', to.params.id)
        //
        //     if (batch) {
        //         return next()
        //     } else {
        //         next({
        //             name: 'NotFound',
        //             pathMatch: to.substring(1).split('/'),
        //             query: to.query,
        //             hash: to.hash
        //         })
        //     }
        // }
    },
    {
        path: '/loading/:id',
        name: 'LoadingShow',
        component: LoadingShow,
        meta: {toTop: true, smoothScroll: true, permission: 'read'},
        props: true
    },
    {
        path: '/arrival/:id',
        name: 'ArrivalShow',
        component: ArrivalShow,
        meta: {toTop: true, smoothScroll: true, permission: 'read'},
        props: true
    },
    {
        path:'/signin',
        name:'SignIn',
        component: SignIn,
        meta: {toTop: true, smoothScroll: true}
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        const scroll = {}
        if (to.meta.toTop) scroll.top = 0
        if (to.meta.smoothScroll) scroll.behavior = 'smooth'

        return { scroll }
    }
})

router.beforeEach(async function(to,from,next) {
    // validate login and permission
    await store.dispatch('tryLogin')

    let userHasPermission = true
    switch (to.meta.permission) {
        case 'admin':
            userHasPermission = store.getters.isAdmin
            break
        case 'read':
            userHasPermission = parseInt(store.getters.userId) > 0
            break
    }
    // console.log(store.getters,store.getters['userId'],store.getters.isAdmin)
    if (userHasPermission) {
        await store.dispatch('setTitle', to.meta.title)
        next()
    } else next('/signin')
})

export default router