<template>
  <ul class="subnav">
    <router-link
        v-for="batch in batches"
        :key="batch.batchId"
        :to="{name: 'BatchShow', params: {id: batch.batchId}}">
      <li class="multiline">
        <label>{{ batch.uniqueNumber + ' ' + getSupplier(batch.supplierId) }}</label><br/>
        für {{ getRegrater(batch.regraterId) }}
        <!--        at <app-date-time :timestamp="batch.publishedAt"/>-->
        <!-- <img src="@/assets/images/pit-story_arrow.svg"> -->
      </li>
    </router-link>
  </ul>
  <div v-if="addBatchEnabled">
    <button @click="showEditor">Neue Charge</button>
    <batch-editor v-if="edit"/>
    <!--  <batch-editor v-if="edit" @save="addBatch"/>-->
  </div>

</template>

<script>
import BatchEditor from "@/components/BatchEditor";

export default {
  name: "BatchList",
  components: {
    BatchEditor
  },
  props: {
    batches: {
      required: false,
      type: Array
    },
    addBatchEnabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      edit: false
    }
  },
  // computed: {
  //   batches() {
  //     return [
  //       {
  //         name: 'Charge 1',
  //         id: 'abc'
  //       },
  //       {
  //         name: 'Charge 2',
  //         id: 'abd'
  //       }
  //     ]
  //   }
  // },
  methods: {
    showEditor() {
      this.edit = true;
    },
    // addBatch(eventData) {
    // const batch = {
    //   ...eventData.batch
    // }
    // this.batches.push(batch)
    // },
    hasHistory(batch) {
      return batch.history?.length
    },
    getSupplier(id) {
      return this.$parent.getSupplier(id)
    },
    getRegrater(id) {
      return this.$parent.getRegrater(id)
    }
  }
}
</script>

<style scoped>

</style>