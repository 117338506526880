<template>
  <header class="row">
    <div class="col-8">
      <router-link :to="{name:'Home'}"><img class="logo" src="@/assets/images/pit-story-logo.svg" alt=""></router-link>
      <img class="titelbild" src="@/assets/images/header-pit-256.jpg" alt="">
    </div>
    <div class="col-6">{{ pageTitle }}</div>
  </header>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "TheHeader",
  computed: {
    ...mapGetters(['pageTitle'])
  }
}
</script>

<style scoped>

</style>