<template>
  <div class="row">
    <h1>Charge <span v-if="batchId" class="key heavy">{{ batch.uniqueNumber }}</span></h1>
    <ul class="output col-8 mb-0">
      <li><label class="col-2">Lieferant</label> {{ getSupplier(batch.supplierId) }}</li>
      <li v-if="batch.qualityStandard"><label class="col-2">Standard</label> {{ batch.qualityStandard }}</li>
      <li><label class="col-2">Aufkäufer</label> {{ getRegrater(batch.regraterId) }}</li>
      <li v-if="batch.washedWeight"><label class="col-2">Nass</label> {{ parseInt(batch.washedWeight) }} kg</li>
      <li v-if="batch.washedQuality"><label class="col-2">Qualität</label> {{ batch.washedQuality }}</li>
      <li v-if="batch.driedWeight"><label class="col-2">Trocken</label> {{ parseInt(batch.driedWeight) }} kg</li>
    </ul>
    <div class="w-100 mb-3">
      <div v-if="batchId" class="col-8 text-right">
        <api-pdf-link method="batchslip" :elementId="batchId" class="float-right"/>
      </div>
    </div>
  </div>
  <div v-if="nextStep === 'wet'"
       class="row">
    <bag-list :batch="batch" :bags="bags" :workflow-state="0"/>
  </div>
  <div v-if="nextStep === 'dry'"
       class="row">
    <bag-list :batch="batch" :bags="bags" :workflow-state="1"/>
    <!--    <h2>Trocknen</h2>-->
    <!--    <form @submit.prevent="save" class="col-8">-->
    <!--&lt;!&ndash;      <input name="formName" value="dry" type="hidden"/>&ndash;&gt;-->
    <!--      <div class="input-group mb-3">-->
    <!--        <div class="input-group-prepend">-->
    <!--          <label class="input-group-text" for="dryWeight">Trockengewicht</label>-->
    <!--        </div>-->
    <!--        <input v-model="dryWeight" type="number" class="form-control form-control-lg"-->
    <!--               id="dryWeight" placeholder="">-->
    <!--        <div class="input-group-append">-->
    <!--          <span class="input-group-text">kg</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <button type="submit">Speichern</button>-->
    <!--    </form>-->
  </div>
  <div v-else-if="nextStep === 'stock' || nextStep === 'loading'"
       class="row">
    <bag-list :batch="batch" :bags="bags" :workflow-state="2"/>
    <h2>Lagerliste</h2>
    <div class="col-2">{{ batch.uniqueNumber }}</div>
    <div class="col-6 text-right"><api-pdf-link method="stockslip" :elementId="batch.batchId" label="PDF" class="float-right"/></div>
<!--    <div class="col-3 text-right">PDF versenden</div>-->
  </div>
  <div v-if="nextStep === 'loading' && batch.driedWeight"
       class="row">
    <div class="col-8">
      <router-link :to="{name:'LoadingShow'}">
        <button>Verladen</button>
      </router-link>
    </div>
  </div>
  <app-back-button :router-link="backLink"/>
</template>

<script>
import BagList from "@/components/BagList";
import ApiPdfLink from "@/components/ApiPdfLink";

export default {
  name: "PageBatchShow",
  components: {BagList,ApiPdfLink},
  data() {
    return {
      batchId: 0,
      activeBatch: {},
      step: '',
      dryWeight: null,
      backLink:''
    }
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  async created() {
    this.setBatchId(this.id)

    // console.log('------------------created')
    await this.$store.dispatch('getItemList', {entityName: 'batch'})
    await this.$store.dispatch('getItemsByParent', {entityName: 'vessel', parentEntity: 'batch', parentId: this.batchId})
    await this.$store.dispatch('getItemList', {entityName: 'supplier'})
    await this.$store.dispatch('getItemList', {entityName: 'regrater'})
    // console.log('------------------created done')
  },
  async beforeMount() {
    // console.log('------------------beforeMount')
    // console.log('------------------beforeMount end')
  },
  async mounted() {
    // console.log('------------------mounted')
    // console.log('------------------mounted end')
  },
  computed: {
    batch() {
      let batch = this.getBatch()
      // console.log('*** computed batch', batch)
      if (batch) {
        if (batch.qualityStandard > 0) {
          const object = this.$store.state.qualityStandards.find(quality => quality.id == batch.qualityStandard)
          if (object) batch.qualityStandard = object.name
        }
      } else
        batch = {}

      return batch
    },
    bags() {
      const batch = this.getBatch()
      if (batch && batch.vessel) return batch.vessel
      else return []
    },
    nextStep() {
      const batch = this.getBatch()

      if (batch) {
        return this.getNextStep(batch)
      }
      return ''
    },
  },
  methods: {
    // batch() {
    //   return this.getBatch()
    // },
    // batch() {
    //   let batch = this.getBatch()
    //   // console.log('*** methods batch', batch)
    //   if (batch) {
    //     if (batch.qualityStandard > 0) {
    //       const object = this.$store.state.qualityStandards.find(quality => quality.id === batch.qualityStandard)
    //       batch.qualityStandard = object.name
    //     }
    //   } else
    //     batch = {}
    //
    //   return batch
    // },
    getBatch() {
      // console.log('getbatch', this.id, this.$store.state.batch)
      return this.$store.state.batch.find(batch => batch.batchId == this.batchId)
    },
    getNextStep(batch) {
      if (batch) {
        if (batch.washedWeight === null) {
          this.step = 'wet'
          this.backLink = 'IncomingPage'
        } else if (!batch.driedWeight) {
          this.step = 'dry'
          this.backLink = 'DryingPage'
        } else if (!batch.vessel) {
          this.step = 'stock'
          this.backLink = 'DryingPage'
        } else if (!batch.loaded) {
          this.step = 'loading'
          this.backLink = 'StockPage'
        }
        return this.step
      }
    },
    getSupplier(id) {
      if (!id || !this.$store.state.supplier) return ''
      const item = this.$store.state.supplier.find(item => item.contactId === id)
      if (!item) return ''
      return item.name
    },
    getRegrater(id) {
      if (!id || !this.$store.state.regrater) return ''
      const item = this.$store.state.regrater.find(item => item.contactId === id)
      if (!item) return ''
      return item.name
    },
    save() {
      let batch = {}

      switch (this.step) {
        case 'wet':
          document.write('step wet')
          // store vessel with workflow state washed and wet weight
          break
        case 'dry':
          batch = {
            dryWeight: this.dryWeight
          }
          this.$store.dispatch('updateBatch', {id: this.batchId, batch})
          this.dryWeight = null;
          this.step = 'stock'
          break
      }
    },
    setBatchId(id) {
      const pos = id.indexOf('.')
      if (pos >= 0)
        id = id.substring(0, pos)
      if (id.indexOf('CH') === 0)
        id = id.substring(2)

      this.batchId = id
    }
  }
}
</script>

<style scoped>

</style>