<template>
  <h1>Login</h1>
  <form @submit.prevent="signIn">
    <div class="form-row">
      <div class="col-8 col-md-4 col-lg-3 col-xl-2">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="email">E-Mail</label>
          </div>
          <input v-model="form.email" class="form-control form-control-lg" id="email" placeholder="">
        </div>
      </div>
      <div class="col-8 col-md-4 col-lg-3 col-xl-2">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="password">Passwort</label>
          </div>
          <input v-model="form.password" class="form-control form-control-lg" id="password" type="password"
                 placeholder="">
        </div>
      </div>
      <div v-if="form.err" class="col-8">
        <div class="alert alert-danger" role="alert">
          {{ form.err }}
        </div>
      </div>
      <div class="col-8">
        <button type="submit">Login</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "SignIn",
  data() {
    return {
      form: {
        email: '',
        password: '',
        err: ''
      }
    }
  },
  methods: {
    async signIn() {
      this.form.err = null
      const response = await this.$store.dispatch('signIn', {...this.form})

      if (response.success) this.$router.push('/')
      else if (response.err) this.form.err = response.err
    }
  }
}
</script>

<style scoped>

</style>