<template>
  <h1>Trocknen</h1>
  <batch-list :batches="batches"/>
  <app-back-button/>
</template>

<script>
import BatchList from "@/components/BatchList";

export default {
  name: "DryingPage",
  components: {
    BatchList
  },
  async created() {
    this.$store.state.batch = []
    await this.$store.dispatch('getItemList', {entityName: 'batch', method: 'washed'})
    await this.$store.dispatch('initItems', {entityName: 'supplier'})
    await this.$store.dispatch('initItems', {entityName: 'regrater'})
  },
  computed: {
    batches() {
      return this.$store.state.batch
      // return this.$store.state.batch.filter(batch => batch.washedWeight === null)
    },
  },
  methods: {
    getSupplier(id) {
      if (!id || !this.$store.state.supplier) return ''
      const item = this.$store.state.supplier.find(item => item.contactId === id)
      if (!item) return ''
      return item.name
    },
    getRegrater(id) {
      if (!id || !this.$store.state.regrater) return ''
      const item = this.$store.state.regrater.find(item => item.contactId === id)
      if (!item) return ''
      return item.name
    },
  }
}
</script>

<style scoped>

</style>