<template>
  <the-header />
  <the-navigation />
  <main>
    <router-view/>
  </main>
</template>

<script>
import TheNavigation from "@/components/TheNavigation";
import TheHeader from "@/components/TheHeader";

export default {
  name: 'App',
  components: { TheHeader, TheNavigation },
  created() {
    // console.log('App created tryLogin')
    this.$store.dispatch('tryLogin').then(this.sessionIsAlive.bind(this))
  },
  methods: {
    sessionIsAlive(isAlive) {
      // console.log('result alive:',isAlive)
      if (!isAlive) this.$router.push('/signin')
    }
  }
}
</script>

<style>
</style>
